import { render, staticRenderFns } from "./projectParticipantsBoxes.vue?vue&type=template&id=361d1ad6&scoped=true"
import script from "./projectParticipantsBoxes.vue?vue&type=script&lang=js"
export * from "./projectParticipantsBoxes.vue?vue&type=script&lang=js"
import style0 from "./projectParticipantsBoxes.vue?vue&type=style&index=0&id=361d1ad6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361d1ad6",
  null
  
)

export default component.exports