import { render, staticRenderFns } from "./groundWaterInfoWidget.vue?vue&type=template&id=873a1a28&scoped=true"
import script from "./groundWaterInfoWidget.vue?vue&type=script&lang=js"
export * from "./groundWaterInfoWidget.vue?vue&type=script&lang=js"
import style0 from "./groundWaterInfoWidget.vue?vue&type=style&index=0&id=873a1a28&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "873a1a28",
  null
  
)

export default component.exports