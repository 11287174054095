export default {
  isDateBetweenDec17AndJan5(inputDate = new Date()) {
    // Extract the day and month from the input date
    const day = inputDate.getDate()
    const month = inputDate.getMonth() // 0 = January, 11 = December

    // Check if the date is between December 17th and December 31st
    const inDecember = month === 11 && day >= 17

    // Check if the date is between January 1st and January 5th
    const inJanuary = month === 0 && day <= 5

    // Return true if the date is in the specified range
    return inDecember || inJanuary
  },

  getLogoPath(root) {
    if (this.isDateBetweenDec17AndJan5()) {
      return `${root}assets/img/christmas-logo-terraindex.svg`
    }

    return `${root}assets/img/logo-terraindex.svg`
  },

  getNewsItemIcon() {
    if (this.isDateBetweenDec17AndJan5()) {
      return `fa-solid fa-tree`
    }

    return `fa-solid fa-gift`
  }
}
