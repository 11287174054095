const exportItems = [
  {
    group: 'TerraIndex',
    options: [
      {
        name: 'TerraIndex',
        exportServerIdentifier: 'Export_FIELD_XML_v1_0_0',
        extension: 'ti',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true,
        useCompression: true
      }
    ]
  },
  {
    group: 'SIKB',
    options: [
      {
        name: 'SIKB13',
        exportServerIdentifier: 'Export_IMSIKB_v13_5_0',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      },
      {
        name: 'SIKB14_4',
        exportServerIdentifier: 'Export_IMSIKB_v14_3_0',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      },
      {
        name: 'SIKB14_7',
        exportServerIdentifier: 'Export_IMSIKB_v14_7_0',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      },
      {
        name: 'SIKB14_8',
        exportServerIdentifier: 'Export_IMSIKB_v14_8_0',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      }
    ]
  },
  {
    group: 'Internal',
    options: [
      {
        name: 'Streamline_Export',
        exportServerIdentifier: 'Export_Streamline_Single_JSON',
        extension: 'json',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true
      },
      {
        name: 'Streamline_Report_Dataset',
        exportServerIdentifier: 'Export_Streamline_Presentation_Single_JSON',
        extension: 'json',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true
      }
    ]
  },
  {
    group: 'Mistral',
    options: [
      {
        name: 'Mistral',
        exportServerIdentifier: 'Export_OVAM_MISTRAL2_v8_4_0',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      }
    ]
  },
  {
    group: 'BRO',
    options: [
      {
        name: 'BROBHR',
        exportServerIdentifier: 'Export_DELPHI_BRO_BHR_GT_BMB',
        extension: 'zip',
        Measurementpoints: true
      },
      {
        name: 'BROGMW',
        exportServerIdentifier: 'Export_BRO_GMW',
        extension: 'zip',
        Measurementpoints: true
      },
      {
        name: 'BROBHRGT',
        exportServerIdentifier: 'Export_BRO_BHR_GT',
        extension: 'zip',
        Measurementpoints: true
      },
      {
        name: 'BROGAR',
        exportServerIdentifier: 'Export_BRO_GAR',
        extension: 'zip',
        Measurementpoints: true,
        WaterSamples: true
      },
      {
        name: 'BROSAD_Registration',
        exportServerIdentifier: 'Export_BRO_SAD_Registration',
        preExportChecks: ['BROSADIdCheck'],
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      },
      {
        name: 'BROSAD_Correction',
        exportServerIdentifier: 'Export_BRO_SAD_Correction',
        preExportChecks: ['BROSADIdCheck'],
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      }
    ]
  },
  {
    group: 'DOV',
    options: [
      {
        name: 'DOV',
        exportServerIdentifier: 'Export_DOV',
        extension: 'zip',
        Measurementpoints: true
      }
    ]
  },
  {
    group: 'Legacy',
    options: [
      {
        name: 'SIKB9',
        exportServerIdentifier: 'Export_DELPHI_SIKB',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      },
      {
        name: 'SIKB12',
        exportServerIdentifier: 'Export_IMSIKB_v12_0_0',
        Measurementpoints: true,
        AnalysisSamples: true,
        WaterSamples: true,
        AirSamples: true
      },
      {
        name: 'TIPDX',
        exportServerIdentifier: 'Export_DELPHI_PARADOX',
        extension: 'zip',
        Measurementpoints: true
      },
      {
        name: 'TIDB',
        exportServerIdentifier: 'Export_DELPHI_DBASE',
        extension: 'zip',
        Measurementpoints: true
      },
      {
        name: 'GEF',
        exportServerIdentifier: 'Export_DELPHI_GEF',
        extension: 'zip',
        Measurementpoints: true
      }
    ]
  }
]

export default exportItems
