import { render, staticRenderFns } from "./waterSamplesWidget.vue?vue&type=template&id=18920d45&scoped=true"
import script from "./waterSamplesWidget.vue?vue&type=script&scoped=true&lang=js"
export * from "./waterSamplesWidget.vue?vue&type=script&scoped=true&lang=js"
import style0 from "./waterSamplesWidget.vue?vue&type=style&index=0&id=18920d45&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18920d45",
  null
  
)

export default component.exports