<template>
    <div class="upload-dropzone-body">
        <input id="file" ref="file" type="file" :accept="this.acceptedFormats" multiple @change="handleFileUpload" />
        <div
            class="dropzone"
            :class="{ draggedOver: dragOver }"
            @drop="dropHandler"
            @dragover="dragOverHandler"
            @dragexit="dragOutHandler"
            @dragleave="dragOutHandler"
            @dragend="dragOutHandler"
            @click="openFilePicker" >
            <span v-if="files.length < 1" class="glyphicons glyphicons-cloud-upload dropzoneIcon"></span>
            <span v-if="files.length < 1" class="fileName">{{ $t("label.DragAndDrop") }}</span>
            <span v-if="files.length > 0 && !uploading" class="glyphicons dropzoneIcon" :class="uploadedIcon"></span>
            <span v-if="uploading" class="glyphicons glyphicons-refresh dropzoneIcon spinning"></span>
            <span v-if="files.length === 1" class="fileName">{{ files[0].name }}</span>
            <span v-if="files.length > 1" class="fileName">{{ $t("label.multipleFiles", {fileCount: files.length}) }}</span>
        </div>
    </div>
</template>

<script>

export default {
  name: 'UploadDropzone',
  props: {
    supportedTypes: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    uploading: {
      type: Boolean,
      required: true
    },
    uploadedIcon: {
      type: String,
      required: false,
      default () {
        return 'glyphicons-file'
      }
    }
  },
  data () {
    return {
      dragOver: false,
      files: []
    }
  },
  computed: {
    acceptedFormats () {
      let supportedTypes = this.supportedTypes.toString()
      let acceptedFormats = `.${supportedTypes}`
      return acceptedFormats.replaceAll(',', ',.')
    }
  },
  methods: {
    dragOverHandler (e) {
      e.preventDefault()
      this.dragOver = true
    },
    dragOutHandler (e) {
      this.dragOver = false
    },
    dropHandler (e) {
      e.preventDefault()
      this.files = e.dataTransfer.files
      this.$emit('filesForUpload', this.files)
      this.dragOver = false
    },
    openFilePicker () {
      if (this.uploading) return
      this.$refs.file.click()
    },
    handleFileUpload (event) {
      this.files = event.target.files
      this.$emit('filesForUpload', this.files)
    }
  }
}
</script>

  <style scoped lang="less">
  .dropzone {
    margin-top: 1rem;
    display: block;
    content: "";
    /*border: dashed 2px #333;*/
    text-align: center;
    cursor: pointer;
    width: 100%;

    .dropzoneIcon {
      font-size: 10rem;
      color: #333;
      display: block;
    }

    .fileName {
      display: block;
    }

    .spinning {
      animation: completeSpin 1.2s linear infinite;
    }

    @keyframes completeSpin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  #file {
    display: none;
  }
  .draggedOver {
    .dropzoneIcon {
      color: #67ac45;
      display: block;
    }
  }
  </style>
