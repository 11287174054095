import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import actions from '@/vuex/actions'
import getters from '@/vuex/getters'
import { dateLastChangedMapping, typeListMapping, CONTACT_PERSON_FUNCTIONS_CODE_LIST, CONCERNED_COMPANY_TYPES_CODE_LIST } from './constants'
import NENClassificationPlugin from './plugins/NENClassificationPlugin'
import MeasurementPointDepthPlugin from './plugins/MeasurementPointDepthPlugin'
import UpdateDefaultCoordinateSystemPlugin from './plugins/UpdateDefaultCoordinateSystemPlugin'

Vue.use(Vuex)

const state = {
  activeEditorValidationPending: false,
  prevTitle: '',
  activeTitle: '',
  currentProject: '',
  prTypeCode: '',
  prTemplateID: '',
  templateFields: {
    matrixcodeList: [],
    finishtypeList: [],
    MpTypeCodeList: [],
    LaSoilTypeList: []
  },
  codeLists: {
    [CONTACT_PERSON_FUNCTIONS_CODE_LIST]: null,
    [CONCERNED_COMPANY_TYPES_CODE_LIST]: null
  },
  // list of concerned contacts that have been loaded
  concernedContacts: [],
  // list of projects that have been loaded
  projects: [],
  // list of measurement points that have been loaded
  measurementPoints: [],
  // list of layers that have been loaded
  layers: [],
  // list of soil samples that have been loaded for the detail view
  soilSamples: [],
  // list of gauging tubes that have been loaded
  gaugingTubes: [],
  // list of finishings that have been loaded
  finishings: [],
  // list of water samples that have been loaded
  waterSamples: [],
  // list of surface water samples that have been loaded
  surfaceWaterSamples: [],
  // list of bottles that have been loaded
  bottles: [],
  // list of observations
  observations: [],
  // list of airsamples that have been loaded
  airSamples: [],
  // list of surfaceAirsamples that have been loaded
  surfaceAirSamples: [],
  // list of packagings that have been loaded
  packagings: [],
  // list of pictures that have been loaded
  pictures: [],
  // list of map images that have been loaded
  mapImages: [],
  // list of subLocations that have been loaded
  subLocations: [],
  // list of the labassignments that have been loaded
  labAssignments: [],
  // object that is currently being edited, e.g. a measurement point
  workingObject: null,
  // string indicating the type of 'workingObject'. for the list of supported workingObjectTypes see constants.js (!!)
  workingObjectType: null,
  // boolean indicating whether changes have been applied to workingObject
  workingObjectChanged: false,
  // index of changed object
  workingObjectIndex: null,
  // Selected element for context menu {Type, optional attributes}
  selectedElement: {},
  // tables (named the same as)
  cadastralOvam: [],
  // tables (named the same as)
  cadastralOvamVlaremAct: [],
  // tables (named the same as)
  cadastralOvamCustomers: [],
  // list of all the mapLayers for a project
  mapLayers: [],
  // list of all the documents of a project
  projectDocuments: [],
  // list of all the KLIC files of a project
  klicFiles: [],
  // list of all the lab certificates files of a project
  labCertificates: [],
  // All dynamic codelists
  dynamicCodeLists: [],
  // Selected element for 'master' (upper) table
  selected: {},
  // Summary and Conclusions data
  summaryAndConclusions: [],
  summaryAndConclusionsTypes: [],
  // Overview queries
  overviewQueries: [],
  // Records of a given Overview query
  overviewQueryResponse: [],
  // The currently selected Overview
  currentOverview: null,
  // The currently selected Overview Parameters
  currentOverviewParameters: null,
  // global loading state
  loading: false,
  // A list of all the jars for the table entry
  tableJars: [],
  // A list of all the layers for the table entry
  tableLayers: [],
  // lab assignment codes
  labAssignmentStatusCodes: [],
  // SIKB lab status codes
  sikbLabStatusCodes: [],
  // Field Matrix Codes
  fieldMatrixCodes: [],
  // News Items
  newsItems: [],
  // Export Projects
  exportInformationExportProjects: [],
  // Analysis Samples
  analysisSamples: []
}

// mutations
const mutations = {

  /**
   * Gets the loading state of the application
   *
   * @param {*} state
   * @param {*} value
   */
  loading (state, value) {
    state.loading = value
  },

  /**
   * Clears the list of available Overview queries
   *
   * @param {*} state
   * @param {*} value
   */
  clearOverviewQueryResponse (state, value) {
    state.overviewQueryResponse = []
  },

  /**
   * Sets the list of available Overview queries
   *
   * @param {*} state
   * @param {*} value
   */
  setOverviewQueryResponse (state, value) {
    state.overviewQueryResponse = value
  },

  /**
   * Sets the code lists for the lab assignments status
   *
   * @param {*} state
   * @param {*} value
   */
  setLabAssignmentStatusCodes (state, value) {
    state.labAssignmentStatusCodes = value
  },

  /**
   * Set the list of SIKB codes
   *
   * @param {*} state
   * @param {*} value
   */
  setSikbLabStatusCodes (state, value) {
    state.sikbLabStatusCodes = value
  },

  /**
   * Sets the list of field Matrix codes
   *
   * @param {*} state
   * @param {*} value
   */
  setFieldMatrixCodes (state, value) {
    state.fieldMatrixCodes = value
  },

  /**
   * Sets the list of available Overview queries
   *
   * @param {*} state
   * @param {*} value
   */
  setOverviewQueries (state, value) {
    state.overviewQueries = value
  },

  /**
   * Sets the list of available Overview queries
   *
   * @param {*} state
   * @param {*} value
   */
  clearExportInformationExportProjects (state, value) {
    state.exportInformationExportProjects = []
  },

  SET_ACTIVE_TITLE (state, value) {
    state.activeTitle = value
  },
  SET_PREV_TITLE (state, value) {
    state.prevTitle = value
  },
  SET_CURRENT_PROJECT (state, value) {
    state.currentProject = value
    if (value instanceof Object) {
      sessionStorage.setItem('CURRENT_PROJECT', JSON.stringify(value))
      return
    }
    sessionStorage.setItem('CURRENT_PROJECT', value)
  },
  SET_PR_TYPE_CODE (state, value) {
    state.prTypeCode = value
    if (value instanceof Object) {
      sessionStorage.setItem('PR_TYPE_CODE', JSON.stringify(value))
      return
    }
    sessionStorage.setItem('PR_TYPE_CODE', value)
  },
  SET_PR_TEMPLATE_ID (state, value) {
    state.prTemplateID = value
    if (value instanceof Object) {
      sessionStorage.setItem('PR_TEMPLATE_ID', JSON.stringify(value))
      return
    }
    sessionStorage.setItem('PR_TEMPLATE_ID', value)
  },
  SET_TEMPLATE_FIELD (state, value) {
    state.templateFields[value.key] = value.value
  },
  setActiveEditorValidationPending (state, activeEditorValidationPending) {
    state.activeEditorValidationPending = activeEditorValidationPending
  },

  /***
   * Set state.layers to layers
   * @param state Object containing the state
   * @param layers Array with layers (objects)
   */
  setLayers (state, layers) {
    state.layers = layers
  },

  /***
   * Indicate a new layer is added. Only used as an event trigger, no state is altered!
   * Introduced so that MeasurementPointDepthPlugin can listen to this mutation.
   */
  newLayer (state, layers) {
    // Nothing
  },
  /***
   * Set state.soilSamples to soilSamples
   * @param state Object containing the state
   * @param soilSamples Array with soil samples (objects)
   */
  setSoilSamples (state, soilSamples) {
    state.soilSamples = soilSamples
  },
  /***
   * Set state.gaugingTubes to gaugingTubes
   * @param state Object containing the state
   * @param gaugingTubes Array with gauging tubes (objects)
   */
  setGaugingTubes (state, gaugingTubes) {
    state.gaugingTubes = gaugingTubes
  },
  /***
   * Set state.pictures to pictures
   * @param state Object containing the state
   * @param pictures Array with pictures (objects)
   */
  setPictures (state, pictures) {
    state.pictures = pictures
  },
  /***
   * Set state.mapImages to mapImages
   * @param state Object containing the state
   * @param mapImages Array with map images (objects)
   */
  setMapImages (state, mapImages) {
    state.mapImages = mapImages
  },
  /***
   * Set state.finishings to finishings
   * @param state Object containing the state
   * @param finishings Array with soil samples (objects)
   */
  setFinishings (state, finishings) {
    state.finishings = finishings
  },
  /***
   * Set state.waterSamples to waterSamples
   * @param state Object containing the state
   * @param waterSamples Array with soil samples (objects)
   */
  setWaterSamples (state, waterSamples) {
    state.waterSamples = waterSamples
  },
  /***
   * Set state.waterSamples to waterSamples
   * @param state Object containing the state
   * @param waterSamples Array with soil samples (objects)
   */
  setSurfaceWaterSamples (state, surfaceWaterSamples) {
    state.surfaceWaterSamples = surfaceWaterSamples
  },
  /***
   * Set state.bottles to bottles
   * @param state Object containing the state
   * @param bottles Array with soil samples (objects)
   */
  setBottles (state, bottles) {
    state.bottles = bottles
  },
  /***
   * Set Observations to state.setObservations
   * @param state
   * @param setObservations
   */
  setObservations (state, setObservations) {
    state.observations = setObservations
  },
  /***
   * Set state.airSamples to airSamples
   * @param state Object containing the state
   * @param airSamples Array with air samples (objects)
   */
  setAirSamples (state, airSamples) {
    state.airSamples = airSamples
  },
  /***
   * Set state.airSamples to airSamples
   * @param state Object containing the state
   * @param airSamples Array with air samples (objects)
   */
  setSurfaceAirSamples (state, surfaceAirSamples) {
    state.surfaceAirSamples = surfaceAirSamples
  },
  /***
   * Set state.packagings to packagings
   * @param state Object containing the state
   * @param packagings Array with packagings (objects)
   */
  setPackagings (state, packagings) {
    state.packagings = packagings
  },
  /***
   * Set state.measurementPoints to measurementPoints
   * @param state Object containing the state
   * @param measurementPoints Array with measurement points (objects)
   */
  setMeasurementPoints (state, measurementPoints) {
    state.measurementPoints = measurementPoints
  },

  /***
   * Set state.projects to projects
   * @param state Object containing the state
   * @param projects Array with projects (objects)
   */
  setProjects (state, projects) {
    state.projects = projects
  },
  /***
   * Set state.concernedContacts to concernedContacts
   * @param state Object containing the state
   * @param concernedContacts Array with concerned contacts (objects)
   */
  setConcernedContacts (state, concernedContacts) {
    // make sure they are always sorted on contact name
    concernedContacts.sort((a, b) => (a['CcContactPerson'] || '').localeCompare(b['CcContactPerson'] || ''))

    state.concernedContacts = concernedContacts
  },
  /***
   * Set state.codeLists[type] to list
   * @param state Object containing the state
   * @param payload Object containing type of list (see vuex/constants) and it's value.
   */
  setCodeList (state, payload) {
    state.codeLists[payload.list] = payload.value
  },
  /***
   * Merge state.workingObject with the payload object.
   * ONLY CALL WHEN PAYLOAD ACTUALLY CONTAINS CHANGES AS WE ARE ALWAYS SETTING state.workingObjectChanged TO TRUE
   * @param state Object containing the state
   * @param payload Object containing the changes that must be merged in state.workingObject (e.g. { MpName: 'MP-01' })
   */
  updateWorkingObject (state, payload) {
    let dateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DDTHH:mm:ss')
    payload = Object.assign(payload, { [dateLastChangedMapping[state.workingObjectType]]: dateLastChanged })

    // create new working object baesd on workingObject and payload. this forces reactivity of all properties.
    // see https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
    state.workingObject = Object.assign({}, state.workingObject, payload)
    state.workingObjectChanged = true

    // if index and type are set update list
    if ((state.workingObjectType !== null) && (state.workingObjectIndex !== null)) {
      // Vue.set(state[state.workingObjectType+'s'],state.workingObjectIndex,state.workingObject);
      // Vue.set(state.measurementPoints,state.workingObjectIndex,state.workingObject);

      Vue.set(state[typeListMapping[state.workingObjectType]], state.workingObjectIndex, state.workingObject)
      // state[typeListMapping[state.workingObjectType]].$set(state.workingObjectIndex, state.workingObject)
    }
  },
  /***
   * Set state.workingObject to workingObject and reset state.workingObjectChanged.
   * ONLY CALL WHEN INITIALIZING A state.workingObject, CALL updateWorkingObject FOR APPLYING CHANGES
   * @param state Object containing the state
   * @param workingObject Object that must be set as workingObject (e.g. a measurementpoint)
   */
  setWorkingObject (state, workingObject) {
    state.workingObject = workingObject
    state.workingObjectChanged = false
  },
  clearWorkingObject (state) {
    state.workingObject = null
    state.workingObjectType = null
    state.workingObjectChanged = false
    state.workingObjectIndex = null
  },
  /***
   * Set state.workingObjectType to workingObjectType.
   * @param state Object containing the state
   * @param workingObjectType String indicating the type of state.workingObject (e.g. measurementPoint). See constants.js for the supported workingObjectTypes.
   */
  setWorkingObjectType (state, workingObjectType) {
    state.workingObjectType = workingObjectType
  },
  /***
   * Set state.workingObjectChanged to workingObjectChanged. This is used to keep track of whether state.workingObject has changed.
   * @param state Object containing the state
   * @param workingObjectChanged Boolean indicating whether state.workingObject has changed.
   */
  setWorkingObjectChanged (state, workingObjectChanged) {
    state.workingObjectChanged = workingObjectChanged
  },
  /***
   * Set state.workingObjectChanged to workingObjectChanged. This is used to keep track of whether state.workingObject has changed.
   * @param state Object containing the state
   * @param workingObjectIndex Integer indicating position of wokring object in state."type"s eg. type = project, state.projects
   */
  setWorkingObjectIndex (state, workingObjectIndex) {
    state.workingObjectIndex = workingObjectIndex
  },
  /***
   * Set state.selectedElement to selectedElement
   * @param state Object containing the state
   * @param selectedElement generated object containing information of the current selected element
   */
  setSelectedElement (state, selectedElement) {
    state.selectedElement = selectedElement
  },

  setCadastralOvams (state, tblCadastralOvam) {
    state.cadastralOvam = tblCadastralOvam
  },

  setCadastralOvamVlaremActs (state, tblCadastralOvamVlaremAct) {
    state.cadastralOvamVlaremAct = tblCadastralOvamVlaremAct
  },

  setCadastralOvamCustomers (state, tblCadastralOvamCustomers) {
    state.cadastralOvamCustomers = tblCadastralOvamCustomers
  },

  setSubLocations (state, subLocations) {
    state.subLocations = subLocations
  },

  setLabAssignments (state, labAssignments) {
    state.labAssignments = labAssignments
  },

  setMapLayers (state, mapLayers) {
    state.mapLayers = mapLayers
  },

  setProjectDocuments (state, projectDocuments) {
    state.projectDocuments = projectDocuments
  },

  setKlicFiles (state, klicFiles) {
    state.klicFiles = klicFiles
  },

  setLabCertificates(state, labCertificates) {
    state.labCertificates = labCertificates
  },

  setDynamicCodeLists (state, dynamicCodeLists) {
    state.dynamicCodeLists = dynamicCodeLists
  },

  setSelected (state, selected) {
    state.selected = selected
  },

  setCurrentProject (state, project) {
    state.currentProject = project
  },

  setSummaryAndConclusions (state, snc) {
    state.summaryAndConclusions = snc
  },

  addSummaryAndConclusion (state, context) {
    state.summaryAndConclusions.push(context)
  },

  summaryAndConclusionsTypesMutation (state, context) {
    state.summaryAndConclusionsTypes = context
  },

  mutateCurrentOverview (state, context) {
    state.currentOverview = context
  },

  mutateCurrentOverviewParameters (state, context) {
    state.currentOverviewParameters = context
  },

  setTableJars (state, jars) {
    if (jars.length > 0) {
      let filterdTable = state.tableJars.filter((jar) => {
        return jar.MpGuid !== jars[0].MpGuid
      })
      filterdTable.push(...jars)
      state.tableJars = filterdTable
    }
  },

  setTableLayers (state, context) {
    if (context.layers.length > 0) {
      let filterdTable = state.tableLayers.filter((layer) => {
        return layer.MpGUID !== context.layers[0].MpGUID
      })
      filterdTable.push(...context.layers)
      state.tableLayers = filterdTable
    } else {
      let filterdTable = state.tableLayers.filter((layer) => {
        return layer.MpGUID !== context.MpGUID
      })
      state.tableLayers = filterdTable
    }
  },

  addExportProject(state, project) {
    if (state.exportInformationExportProjects.some(projects => projects.PrID === project.PrID)) return
    state.exportInformationExportProjects.push(project)
  },

  updateAllExportProjectsBindingKey(state, context) {
    state.exportInformationExportProjects.map((project) => {
      project[context.fieldBindingKey] = context.fieldValue
    })
  },

  updateExportProjectBindingKey(state, context) {
    state.exportInformationExportProjects.forEach((project) => {
      if (project.PrID === context.PrID) {
        project[context.fieldBindingKey] = context.fieldValue
      }
    })
  }
}

const nenClassificationPlugin = NENClassificationPlugin()
const measurementPointDepthPlugin = MeasurementPointDepthPlugin()
const updateDefaultCoordinateSystemPlugin = UpdateDefaultCoordinateSystemPlugin()

// store
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [nenClassificationPlugin, measurementPointDepthPlugin, updateDefaultCoordinateSystemPlugin]
})
