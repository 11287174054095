export default {
  formatArray(object) {
    if (typeof object === 'undefined') {
      return []
    } else if (!Array.isArray(object)) {
      return [object]
    }
    return object
  },

  wrapInArray (obj) {
    return Array.isArray(obj) ? obj : [obj]
  },

  groupBy(array, key, filter) {
    if (typeof array === 'undefined' || array.length <= 0) {
      return []
    } else if (typeof array[0] !== 'object') {
      return array
    }

    return array.reduce((result, currentValue) => {
      const groupKey = String(currentValue[key])

      if (!filter || filter(currentValue)) {
        if (!result[groupKey]) {
          result[groupKey] = []
        }

        result[groupKey].push(currentValue)
      }
      return result
    }, {})
  },

  // An Async foreach function to loop an array and handle the
  // callbacks in a Promise chain
  async asyncForEach(array, asyncCallback) {
    for (let index = 0; index < array.length; index++) {
      await asyncCallback(array[index], index, array)
    }
  },

  getDefaultName(list, field, sortby) {
    let defaultName = ''

    const sortedList = list.sort((a, b) => {
      const aSortBy = parseInt(a[sortby] || 0)
      const bSortBy = parseInt(b[sortby] || 0)
      return bSortBy - aSortBy
    })

    const firstItemInList = sortedList[0] || {}
    const name = firstItemInList[field] || ''

    const match = name.match(/(^\d+)|(\d+$)/g)
    if (match && match.length > 0) {
      const lastIndex = match[match.length - 1]
      if (name.endsWith(lastIndex)) {
        const index = parseInt(lastIndex) + 1
        defaultName = name.substring(0, name.length - lastIndex.length) + index.toString()
      }
    }

    if (defaultName === '' && name !== '') {
      defaultName = name + '1'
    }

    return defaultName
  },

  getNewNameByIncremented(name) {
    const match = name.match(/(^\d+)|(\d+$)/g)

    if (match && match.length > 0) {
      const lastIndex = match[match.length - 1]
      const index = name.endsWith(lastIndex) ? parseInt(lastIndex) + 1 : 1
      return name.substring(0, name.length - lastIndex.length) + index.toString()
    } else if (name !== '') {
      return name + '1'
    } else {
      return ''
    }
  },

  getSuggestedName (data, typePrefix, initialName) {
    const pad = function (input, length) {
      return Array(length - Math.floor(Math.log10(input))).join('0') + input
    }

    let items = data.filter((d) => d.hasOwnProperty(typePrefix + 'ID'))

    // Gather the names of those items
    const prevNames = items.map((i) => {
      return i[typePrefix + 'Name']
    })

    initialName = initialName || prevNames[prevNames.length - 1] || ''

    items.sort((a, b) => {
      return parseInt(a[typePrefix + 'ID']) > parseInt(b[typePrefix + 'ID'])
    })

    if (items.length === 0 && !initialName) {
      return '' // no items, no increment
    } else {
      let result = null
      const rx = new RegExp('\\d+$')
      const check = rx.exec(initialName)

      if (check) {
        let digit = 1 + (parseInt(check[0]))
        const prefix = initialName.substring(0, check.index)
        if (check[0][0] === '0') {
          digit = pad(digit, check[0].length)
        }
        result = [prefix, digit].join('')
      } else {
        result = initialName + '1'
      }

      var existsIndex = prevNames.indexOf(result)
      if (existsIndex !== -1) {
        return this.getSuggestedName(data, typePrefix, prevNames[existsIndex])
      } else {
        return result
      }
    }
  }
}
