<template>
  <div class="manual-analysis-input-overview">
    <div class="overview-table-row">
      <div class="overview-table">
        <DxDataGrid
          :data-source="analysisSamples"
          key-expr="SampleGUID"
          :column-auto-width="true"
          :selection="{ mode: 'single' }"
          :show-borders="true"
          :row-alternation-enabled="true"
          @content-ready="onContentReady"
          @selection-changed="onSampleSelectionChanged"
          @toolbar-preparing="onToolbarPreparing"
        >
          <DxColumn :caption="$t('AnalysesResults.SampleName')" data-field="SampleName" :sorting-method="naturalSort" />
          <DxColumn :caption="$t('AnalysesResults.SampleMatrix')" data-field="SampleMatrix" />
          <DxColumn :caption="$t('AnalysesResults.SampleDate')" data-field="SampleDate" data-type="date" />
          <DxColumn :caption="$t('AnalysesResults.SampleFrom')" data-field="SampleFrom" alignment="left" />
          <DxColumn :caption="$t('AnalysesResults.SampleTo')" data-field="SampleTo" alignment="left" />
          <DxSearchPanel :visible="true" />
          <DxScrolling mode="virtual" row-rendering-mode="standard" />
          <DxGrouping :auto-expand-all="false" />
          <DxGroupPanel :visible="true" />
          <template #tableTitleTemplate>
            <h2>{{ $t('manualAnalysisResultInput.headers.samples') }}</h2>
          </template>
        </DxDataGrid>
      </div>
      <div class="overview-table">
        <DxDataGrid :data-source="sampleComponents" key-expr="GcCodeId" :column-auto-width="true" :show-borders="true" :row-alternation-enabled="true" @toolbar-preparing="onToolbarPreparing">
          <DxColumn caption="ID" data-field="GcCodeId" :sorting-method="naturalSort" />
          <DxColumn :width="300" :caption="$t('AnalysesResults.ComponentGroup')" data-field="ComponentGroupCode">
            <DxLookup display-expr="GcDescription" value-expr="GcCode" :data-source="componentGroups" />
          </DxColumn>
          <DxColumn :caption="$t('AnalysesResults.ResultComponent')" data-field="GcDescription" />
          <DxColumn caption="CAS" data-field="GcDescription2" :sorting-method="naturalSort" />
          <DxColumn type="buttons">
            <DxButton :hint="$t('manualAnalysisResultInput.buttons.AddToSample')" icon="add" :onClick="addToSelectedSample" />
          </DxColumn>
          <DxSearchPanel :visible="true" />
          <DxScrolling mode="virtual" />
          <DxGrouping :auto-expand-all="false" />
          <DxGroupPanel :visible="true" />
          <template #tableTitleTemplate>
            <h2>{{ $t('manualAnalysisResultInput.headers.components') }}</h2>
          </template>
        </DxDataGrid>
      </div>
    </div>
    <div class="overview-table-row">
      <div class="overview-table">
        <DxDataGrid
          ref="resultsTable"
          :key="selectedSampleGuid"
          :data-source="sampleResults"
          key-expr="ResultGUID"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          :onCellDblClick="onCellDblClick"
          :onCellClick="focusCellChange"
          :onFocusedCellChanged="focusCellChange"
          :selection="{ mode: 'single' }"
          @toolbar-preparing="onResultsToolbarPreparting"
          @selection-changed="onResultSelectionChanged"
          @content-ready="onContentReady"
          @saving="onSavingData"
        >
          <DxColumn :width="300" :caption="$t('AnalysesResults.ComponentGroup')" data-field="ComponentGroupCode">
            <DxLookup display-expr="GcDescription" value-expr="GcCode" :data-source="componentGroups" />
          </DxColumn>
          <DxColumn :width="300" :caption="$t('AnalysesResults.ResultComponent')" data-field="ResultComponent" :allow-editing="false" />
          <DxColumn :width="300" :caption="$t('AnalysesResults.ResultMethod')" data-field="ResultMethodCode">
            <DxLookup display-expr="GcDescription" value-expr="GcCode" :data-source="analysesMethods" />
          </DxColumn>
          <DxColumn :caption="$t('AnalysesResults.ResultValue')" data-field="ResultValue" data-type="number" alignment="left" />
          <DxColumn :caption="$t('AnalysesResults.ResultStringValue')" data-field="ResultStringValue">
            <DxStringLengthRule :max="50" :message="$t('message.valueTooLong')"/>
          </DxColumn>
          <DxColumn :caption="$t('AnalysesResults.ResultValueRef')" data-field="ResultValueRef">
            <DxLookup :data-source="['<', '*', '']" />
          </DxColumn>
          <DxColumn :caption="$t('AnalysesResults.ResultUnit')" data-field="ResultUnitCode">
            <DxLookup display-expr="GcDescription" value-expr="GcCode" :data-source="units" />
          </DxColumn>
          <DxColumn :caption="$t('AnalysesResults.ResultReportedDate')" data-field="ResultReportedDate" data-type="date" format="dd-MM-yyyy" />
          <DxSearchPanel :visible="true" />
          <DxScrolling mode="Standard" row-rendering-mode="standard"  />
          <DxColumn :visible="false" type="buttons">
            <!-- DevExtreme includes an action column by default. This removes it. -->
          </DxColumn>
          <DxPaging :page-size="pageSize"/>
          <DxPager :visible="false" allowdPageSiexes="all" />
          <DxGrouping :auto-expand-all="false" />
          <DxGroupPanel :visible="true" />
          <DxEditing :allow-updating="true" :allow-deleting="true" :allow-adding="false" mode="row" />
          <template #tableTitleTemplate>
            <h2>{{ $t('manualAnalysisResultInput.headers.results') }}</h2>
          </template>
        </DxDataGrid>
      </div>
    </div>
    <nv-loading :show="loading" :showTxt="''" />
    <ti-dialog ref="takeOverFromOtherSample">
      <SelectSingleItemFromArray
        :actionDescription="$t('manualAnalysisResultInput.message.action')"
        :dataSet="sampleThatCanBeCopied"
        :actionExplanation="$t('manualAnalysisResultInput.message.explanation')"
        :defaultValue="lastUsedSampleToCopyFrom"
        displayExpresion="SampleName"
        valueExpresion="SampleGUID"
      />
    </ti-dialog>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn, DxSearchPanel, DxLookup, DxScrolling, DxGrouping, DxButton, DxGroupPanel, DxEditing, DxStringLengthRule, DxPager, DxPaging } from 'devextreme-vue/data-grid'

import deDevExtremeLocale from '@/locales/devExtreme/de'
import esDevExtremeLocale from '@/locales/devExtreme/es'
import frDevExtremeLocale from '@/locales/devExtreme/fr'
import itDevExtremeLocale from '@/locales/devExtreme/it'
import nlDevExtremeLocale from '@/locales/devExtreme/nl'
import { locale, loadMessages } from 'devextreme/localization'
import sortUtils from '@/utils/sortUtils'

import nvLoading from '@/components/_shared/loading.vue'
import TiDialog from '@/components/_shared/tiDialog.vue'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'

import SelectSingleItemFromArray from '@/components/modalSelects/selectSingleItemFromArray.vue'

export default {
  name: 'manualAnalysesInput',
  props: {
    projectId: String
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxLookup,
    DxScrolling,
    DxGrouping,
    DxGroupPanel,
    DxButton,
    DxEditing,
    DxStringLengthRule,
    nvLoading,
    TiDialog,
    SelectSingleItemFromArray,
    DxPager,
    DxPaging
  },
  data() {
    return {
      analysisSamples: [],
      componentGroups: [],
      sampleComponents: [],
      analysesMethods: [],
      units: [],
      sampleResults: [],
      filterdComponents: [],
      selectedSampleGuid: '',
      lastUsedSampleToCopyFrom: '',
      loading: false,
      focusRowIndex: 0,
      componentComponentGroupMapping: {},
      resultsOfSampleMapped: []
    }
  },
  computed: {
    sampleThatCanBeCopied() {
      return this.analysisSamples.filter((sample) => {
        return sample.SampleGUID !== this.selectedSampleGuid
      })
    },
    pageSize() {
      return this.sampleResults.length
    }
  },
  async created() {
    this.loading = true
    this.setLanguageForDevExtreme()
    await this.setAllComponents()
    await Promise.all([this.setAllAnalysisSamples(), this.setAllSamplingUnits(), this.setAllAnalysisMethods(), this.setAllComponentGroups()])

    this.loading = false
  },
  methods: {
    setLanguageForDevExtreme() {
      if (Cookie.get('language') === 'en') return
      switch (Cookie.get('language')) {
        case 'nl':
          loadMessages(nlDevExtremeLocale)
          break
        case 'fr':
          loadMessages(frDevExtremeLocale)
          break
        case 'de':
          loadMessages(deDevExtremeLocale)
          break
        case 'es':
          loadMessages(esDevExtremeLocale)
          break
        case 'it':
          loadMessages(itDevExtremeLocale)
          break
      }
      locale(Cookie.get('language'))
    },
    naturalSort(a, b) {
      return sortUtils.anotherNaturalSort(a, b)
    },
    setAllAnalysisSamples() {
      return this.$store
        .dispatch('getAllAnalysisSamples', {
          projectId: this.projectId
        })
        .then((result) => {
          this.analysisSamples = result
        })
    },
    setAllSamplingUnits() {
      return this.$store
        .dispatch('getCodeListByCategoryIDOrCode', {
          categoryId: 73
        })
        .then((result) => {
          this.units = result
        })
    },
    setAllComponents() {
      return this.$store
        .dispatch('getCodeListByCategoryIDOrCode', {
          categoryId: 75
        })
        .then((result) => {
          this.componentComponentGroupMapping = []
          result.forEach((element) => {
            if (element.GcOptions) {
              try {
                element.ComponentGroupCode = JSON.parse(element.GcOptions).Tag || ''
              } catch {
                element.ComponentGroupCode = ''
              }
            } else {
              element.ComponentGroupCode = ''
            }
            this.componentComponentGroupMapping[element.GcCode] = element.ComponentGroupCode
          })
          this.sampleComponents = result
        })
    },
    setAllAnalysisMethods() {
      return this.$store
        .dispatch('getCodeListByCategoryIDOrCode', {
          categoryId: 58
        })
        .then((result) => {
          this.analysesMethods = result
        })
    },
    setAllComponentGroups() {
      return this.$store
        .dispatch('getCodeListByCategoryIDOrCode', {
          categoryId: 78
        })
        .then((result) => {
          this.componentGroups = result
        })
    },
    focusCellChange(data) {
      if (data.rowIndex < 0) return
      if (data.rowIndex === this.focusRowIndex) return
      if (!this.$refs.resultsTable) return
      if (!this.$refs.resultsTable.$_instance) return
      this.focusRowIndex = data.rowIndex
      this.$refs.resultsTable.$_instance.selectRowsByIndexes([data.rowIndex])
    },
    async onSaveButtonClick(event) {
      if (!this.$refs.resultsTable) return
      const grid = this.$refs.resultsTable.$_instance

      if (!grid) return

      if (grid.hasEditData) {
        grid.saveEditData()
      } else {
        grid.cancelEditData()
      }
    },
    onContentReady(e) {
      var grid = e.component
      var selection = grid.getSelectedRowKeys()
      if (selection.length === 0) {
        grid.selectRowsByIndexes([0])
      }
    },
    async onSampleSelectionChanged(selections) {
      this.sampleResults = []
      this.selectedSampleGuid = selections.selectedRowKeys[0]

      await this.getAndSetAllAnalysisResults()
    },
    async takeOverFromOtherSample() {
      const sampleGuidToTakeOverFrom = await this.$refs.takeOverFromOtherSample.open({
        title: this.$t('manualAnalysisResultInput.buttons.takeOverTable'),
        buttonLabel: this.$t('manualAnalysisResultInput.buttons.takeOver')
      })

      if (!sampleGuidToTakeOverFrom) return
      this.lastUsedSampleToCopyFrom = sampleGuidToTakeOverFrom

      this.loading = true
      const resultsOfOriginSample = await this.$store.dispatch('GetAllResults', {
        projectId: this.projectId,
        sampleGUID: sampleGuidToTakeOverFrom
      })
      const resultsOfOriginSampleMapped = resultsOfOriginSample.map((item) => {
        return {
          ResultMethodCode: item.ResultMethodCode,
          ResultUnitCode: item.ResultUnitCode,
          ResultComponentCode: item.ResultComponentCode,
          ProjectID: this.projectId,
          SampleGUID: this.selectedSampleGuid
        }
      })
      const toAddResults = this.getDifference(resultsOfOriginSampleMapped, this.resultsOfSampleMapped)

      if (toAddResults.length <= 0) {
        this.loading = false
        return
      }

      const request = {
        projectId: this.projectId,
        sampleGUID: this.selectedSampleGuid,
        data: toAddResults
      }

      await this.$store.dispatch('CRUDAnalysisResults', request)
      await this.getAndSetAllAnalysisResults(this.projectId, this.selectedSampleGuid)
      this.loading = false
    },
    getDifference(array1, array2) {
      return array1.filter((object1) => {
        return !array2.some((object2) => {
          return object1.ResultMethodCode === object2.ResultMethodCode && object1.ResultUnitCode === object2.ResultUnitCode && object1.ResultComponentCode === object2.ResultComponentCode
        })
      })
    },
    getAndSetAllAnalysisResults(projectId, sampleGUid) {
      return this.$store
        .dispatch('GetAllResults', {
          projectId: this.projectId,
          sampleGUID: this.selectedSampleGuid
        })
        .then((result) => {
          this.sampleResults = result
          // We get a different component group back from the server than we should display. It is not possible to add the code for the component group on the server side easely so we will add it client side to display the correct Component group
          this.sampleResults.forEach((sample) => {
            sample.ComponentGroupCode = this.componentComponentGroupMapping[sample.ResultComponentCode]
          })

          this.resultsOfSampleMapped = this.sampleResults.map((item) => {
            return {
              ResultMethodCode: item.ResultMethodCode,
              ResultUnitCode: item.ResultUnitCode,
              ResultComponentCode: item.ResultComponentCode,
              ProjectID: this.projectId,
              SampleGUID: this.selectedSampleGuid
            }
          })
        })
    },
    onResultSelectionChanged(event) {
      if (event.component.hasEditData()) {
        event.component.saveEditData()
      } else {
        event.component.cancelEditData()
      }
    },
    onResultsToolbarPreparting(event) {
      const saveButton = {
        locateInMenu: 'auto',
        location: 'after',
        name: 'saveButton',
        options: {
          icon: 'save',
          onClick: this.onSaveButtonClick,
          hint: this.$t('label.save'),
          text: this.$t('label.save'),
          onInitialized: () => {}
        },
        showText: 'inMenu',
        sortIndex: 22,
        widget: 'dxButton'
      }
      event.toolbarOptions.items.push(saveButton)

      const takerOverButton = {
        locateInMenu: 'auto',
        location: 'after',
        name: 'takerOverButton',
        options: {
          icon: 'copy',
          onClick: this.takeOverFromOtherSample,
          hint: this.$t('manualAnalysisResultInput.buttons.takeOverTable'),
          text: this.$t('manualAnalysisResultInput.buttons.takeOverTable'),
          onInitialized: () => {}
        },
        showText: 'inMenu',
        sortIndex: 23,
        widget: 'dxButton'
      }
      event.toolbarOptions.items.push(takerOverButton)

      const deleteButton = {
        locateInMenu: 'auto',
        location: 'after',
        name: 'deleteRowButton',
        options: {
          disabled: false,
          icon: 'trash',
          onClick: this.onDeleteButtonClick,
          hint: this.$t('label.delete'),
          text: this.$t('label.delete')
        },
        showText: 'inMenu',
        sortIndex: 25,
        widget: 'dxButton'
      }
      event.toolbarOptions.items.push(deleteButton)

      this.onToolbarPreparing(event)
    },
    onToolbarPreparing(event) {
      event.toolbarOptions.items.unshift({
        location: 'before',
        template: 'tableTitleTemplate',
        sortIndex: 0
      })
      event.toolbarOptions.items.sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1))
    },
    onCellDblClick(event) {
      event.component.editRow(event.rowIndex, event.columnIndex)
    },
    async addToSelectedSample(record) {
      const dataObject = [
        {
          ResultMethodCode: '-1',
          ResultUnitCode: '-1',
          ResultComponentCode: record.row.data.GcCode,
          ProjectID: this.projectId,
          SampleGUID: this.selectedSampleGuid
        }
      ]

      const alreadyExists = this.resultsOfSampleMapped.some((sampleResult) => {
        return (
          sampleResult.ResultMethodCode === dataObject[0].ResultMethodCode &&
          sampleResult.ResultUnitCode === dataObject[0].ResultUnitCode &&
          sampleResult.ResultComponentCode === dataObject[0].ResultComponentCode
        )
      })
      if (alreadyExists) return

      const request = {
        projectId: this.projectId,
        sampleGUID: this.selectedSampleGuid,
        data: dataObject
      }
      this.loading = true
      await this.$store.dispatch('CRUDAnalysisResults', request)
      await this.getAndSetAllAnalysisResults(this.projectId, this.selectedSampleGuid)

      this.loading = false
    },
    async onDeleteButtonClick() {
      const dataRow = this.$refs.resultsTable.$_instance.getSelectedRowsData()[0]
      dataRow.ResultIsDeleted = true
      const dataObject = [dataRow]
      const request = {
        projectId: this.projectId,
        sampleGUID: this.selectedSampleGuid,
        data: dataObject
      }
      this.loading = true
      await this.$store.dispatch('CRUDAnalysisResults', request)

      await this.getAndSetAllAnalysisResults(this.projectId, this.selectedSampleGuid)

      this.loading = false
    },
    async onSavingData(action) {
      if (action.changes.length < 1) {
        return
      }

      if (action.changes[0].type === 'update') {
        this.loading = true
        const rowData = await action.component.getDataByKeys([action.changes[0].key])[0]
        const dataRow = { ...rowData, ...action.changes[0].data }
        const dataObject = [dataRow]
        const request = {
          projectId: this.projectId,
          sampleGUID: this.selectedSampleGuid,
          data: dataObject
        }
        try {
          await this.$store.dispatch('CRUDAnalysisResults', request)
        } catch (e) {
          alert(this.$t('manualAnalysisResultInput.message.ResultNotUnique'))
        }
        await this.getAndSetAllAnalysisResults(this.projectId, this.selectedSampleGuid)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.manual-analysis-input-overview h2 {
  margin-top: 5px;
  font-size: 20px;
}
.manual-analysis-input-overview {
  height: 100%;
}
.manual-analysis-input-overview .overview-table-row {
  height: 50%;
}
.manual-analysis-input-overview .overview-table-row {
  display: flex;
  justify-content: space-between;
}
.manual-analysis-input-overview .overview-table-row:first-child .overview-table:first-child {
  flex: 4;
}
.manual-analysis-input-overview .overview-table-row:first-child .overview-table:nth-child(2) {
  flex: 5;
}
.manual-analysis-input-overview .overview-table-row:nth-child(2) .overview-table:first-child {
  flex: 6;
}
.manual-analysis-input-overview .overview-table-row:nth-child(2) .overview-table:nth-child(2) {
  flex: 3;
}
.manual-analysis-input-overview .overview-table-row .overview-table > div {
  padding-left: 10px;
}
.manual-analysis-input-overview .overview-table-row:nth-child(2) {
  padding-top: 10px;
}
.manual-analysis-input-overview .overview-table {
  overflow: hidden;
}
.manual-analysis-input-overview .overview-table > div,
.manual-analysis-input-overview .overview-table > div > div {
  height: 100%;
}
.manual-analysis-input-overview .overview-table-row .overview-table > div,
.manual-analysis-input-overview .overview-table-row .overview-table > div > div {
  height: 100%;
}
.dx-datagrid-search-panel {
  margin-left: 0px;
}
</style>
