'use strict'
import _ from 'lodash'
const PLATFORM = 'web'

let env
let host = window.location.hostname
switch (
  host // decide config based on hostname
) {
  case 'web.terraindex.com':
    env = 'production'
    break
  case 'staging.terraindex.com':
    env = 'production'
    break
  case 'test.terraindex.com':
    env = 'test'
    break
  case 'test2.terraindex.com':
    env = 'test'
    break
  case 'localhost':
    // env = 'test'
    env = 'development' // on demand switch to develop_local for testing local services
    break
  default: // default to production
    env = 'production'
    console.error('ERROR: could not reliable determine environment for ' + host)
}

let api = 'https://' + window.location.hostname
if (env === 'development') {
  api = 'https://test.terraindex.com'
}

let configProduction = {
  platform: PLATFORM,
  env: 'production',
  root: '/Web/',
  sso: {
    client_id: 'tiweb_client',
    // return_uri should end with a trailing slash - otherwise invalid_grant will be returned and it will be quite hard to debug
    return_uri: location.protocol + '//' + location.host + location.pathname,
    ssoServer: api + '/SSO',
    ssoUrl: 'https://login.terraindex.com/6068759d-67da-467d-b849-4f9fda7c97f8/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=d0a50f00-f586-4698-a691-0aece542cdbf&nonce=defaultNonce&redirect_uri=' +
      encodeURIComponent(location.protocol + '//' + location.host + '/Web/connect-azure') +
      '&scope=openid&response_type=id_token&response_mode=query',
    registerUrl: 'https://login.terraindex.com/6068759d-67da-467d-b849-4f9fda7c97f8/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=d0a50f00-f586-4698-a691-0aece542cdbf&nonce=defaultNonce&redirect_uri=' +
      encodeURIComponent(location.protocol + '//' + location.host + '/Web/connect-azure') +
      '&scope=openid&response_type=id_token&response_mode=query&forward-to-signup',
    preLoginUrl: location.protocol + '//' + location.host + '/Web/login',
    azureLogoutUrl: 'https://login.terraindex.com/6068759d-67da-467d-b849-4f9fda7c97f8/oauth2/v2.0/logout?p=B2C_1A_SIGNUP_SIGNIN&post_logout_redirect_uri='
  },
  apps: {
    web: api + '/Web/',
    import: api + '/ImportAndExport/#/home/i',
    export: api + '/ImportAndExport/#/home/e?',
    verify: api + '/Verification/#/validation?',
    report: api + '/Reports/#/reportlist?',
    labassignment: api + '/LabAssignment/#/wizard/',
    xyzimport: api + '/XYZImport/'
  },
  apis: {
    dataws: api + '/DataWS/',
    mediaws: api + '/MediaWS/',
    managementWs: api + '/ManagementWS/',
    deliveryservice: api + '/DeliveryServiceApplication/WebSite/',
    reportGenerator: api + '/ReportWS/repws/',
    mapLayerProxy: api + '/CorsProxy/proxy.ashx?URL=',
    algorithmService: 'https://ti-api-ms.azure-api.net/classic/algorithm-service/algorithms/suggest-mixed-samples/regions/nl/formats/ti-classic',
    BROService: 'https://acc-publiek.broservices.nl/sq/sad/v1'
  },
  menus: {
    profile: api + '/Management/#/profile',
    user: api + '/Management/#/users',
    fieldtemplate: api + '/Management/#/fieldtemplate',
    codelists: api + '/Management/#/codelists-setting',
    LicenseManagement: api + '/Management/#/applicense',
    CitrixLogin: 'https://terraindex.itworks.nl/Citrix/Small/auth/login.aspx'
  }
}

let configTest = _.merge(_.cloneDeep(configProduction), {
  env: 'test',
  sso: {
    ssoUrl: 'https://terraindexstreamlinetest.b2clogin.com/terraindexstreamlinetest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=c76e486f-72bd-4915-a6ca-da556de1abf0&nonce=defaultNonce&redirect_uri=' +
      encodeURIComponent(location.protocol + '//' + location.host + '/Web/connect-azure') +
      '&scope=openid&response_type=id_token&response_mode=query',
    registerUrl: 'https://terraindexstreamlinetest.b2clogin.com/terraindexstreamlinetest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=c76e486f-72bd-4915-a6ca-da556de1abf0&nonce=defaultNonce&redirect_uri=' +
      encodeURIComponent(location.protocol + '//' + location.host + '/Web/connect-azure') +
      '&scope=openid&response_type=id_token&response_mode=query&forward-to-signup',
    preLoginUrl: location.protocol + '//' + location.host + '/Web/login',
    azureLogoutUrl: 'https://terraindexstreamlinetest.b2clogin.com/terraindexstreamlinetest.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_SIGNUP_SIGNIN&post_logout_redirect_uri='
  },
  apis: {
    algorithmService: 'https://ti-api-ms-test.azure-api.net/classic/algorithm-service/algorithms/suggest-mixed-samples/regions/nl/formats/ti-classic'
  }
})

let configDevelop = _.merge(_.cloneDeep(configTest), {
  env: 'develop',
  root: '/',
  apps: {
    // web: 'http://localhost:8080/Web/'
  },
  apis: {

    // Toggle to debug on local machine beware if you run SSO local also run Dataws local:
    // dataws: 'http://localhost:49295/'
    // managementWs: 'http://localhost:49208/',
    // mediaws: 'https://localhost:44379/'
    // mediaws: 'http://localhost:63626/'
    // algorithmService: 'https://ti-api-ms-test.azure-api.net/classic/algorithm-service/algorithms/suggest-mixed-samples/regions/nl/formats/ti-classic'
    // algorithmService: 'https://localhost:44907/algorithms/suggest-mixed-samples/regions/nl/formats/ti-classic'
  },
  sso: {
    // client_id: 'tiweb_client',
    // // return_uri should end with a trailing slash - otherwise invalid_grant will be returned and it will be quite hard to debug
    // return_uri: location.protocol + '//' + location.host + '/Web/',
    // ssoUrl: 'http://localhost:16939/SSO/oauth/authorize?response_type=code&client_id=tiweb_client&applicationcode=61&featureword=256&redirect_uri=' +
    //     location.protocol +
    //      '//' +
    //     location.host +
    //     location.pathname +
    //     '&state=',
    // ssoServer: 'http://localhost:16939/SSO'
  }
})

let envConfig = {
  production: configProduction,
  test: configTest,
  development: configDevelop
}

if (env !== 'production') {
  console.log('Using environment config for: ' + env)
  console.log(envConfig)
}

export default envConfig[env]
