import Vue from 'vue'
import config from '@/configurations/config-environment'

const serviceUrl = config.apis.BROService

export class BROService {
  getBROID(projectId) {
    return Vue.http.get(`${serviceUrl}/bro-id/${projectId}`)
      .then((response) => {
        if (!response || !response.status === 200) return false
        return response.data
      })
      .catch(() => {
        return false
      })
  }
}
