<template>
  <div class="pre-export-content">
      <p class="pre-export-title">{{getProjectName}}</p>
      <div class="pre-export-container">
        <div v-for="checkComponent in selectedExportConfig.fieldValue.preExportChecks" :key="checkComponent" class="pre-export-component">
          <component :is="checkComponent" />
        </div>
      </div>
  </div>
</template>

<script>
import '@/assets/less/colors.less'
import BROSADIdCheck from './checkComponents/BROSADIdCheck.vue'

export default {
  name: 'PreExportCheck',
  components: {
    BROSADIdCheck
  },
  props: {
    selectedExportConfig: {
      type: Object,
      required: true
    }
  },
  computed: {
    getExportProjects() {
      return this.$store.getters.getExportInformationExportProjects
    },
    getProjectName() {
      return this.getExportProjects[0].PrCode
    }
  }
}
</script>

<style lang="less" scoped>
  .pre-export-content {
      width: 90vw;
      height: calc(80vh - 128px);
      padding: 0px 32px;
      overflow-y: scroll;

    .pre-export-title {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 999;
        color: gray800;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        padding: 10px 0px;
    }

    .pre-export-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .pre-export-component {
        background: @gray100;
        padding: 16px;
      }
    }
  }
</style>
