<template>
  <div id="overview">
    <app-header :tile-view-route="{
      name: 'project',
      params: { projectId: $route.params.projectId.toString() }
    }" :dashboard-view-route="{
        name: 'dashboard',
        params: { projectId: $route.params.projectId.toString(), dashboardId: '1' }
      }" />
    <aside class="asideExpanded">
      <h4 style="color: white; padding-left: 0.5rem; font-size: 22px; font-weight: 400px">
        {{ $t('pages.overview') }}
      </h4>
      <ul class="overview-list">
        <li :key="'108'">
          <router-link :title="$t('ProjectOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '108' } }">
            <span class="overview-button" :style="{ backgroundColor: '#67ac45', color: 'white' }">{{
              $t('ProjectOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'104'">
          <router-link :title="$t('MeasurementPointsOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '104', mpGuid: mpGuid } }">
            <span class="overview-button" :style="{ backgroundColor: '#407525', color: 'white' }">{{
              $t('MeasurementPointsOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'102'">
          <router-link :title="$t('SoilDataTableOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '102', mpGuid: mpGuid } }">
            <span class="overview-button" :style="{ backgroundColor: '#793f10', color: 'white' }">{{
              $t('SoilDataTableOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'103'">
          <router-link :title="$t('GroundWaterDataTableOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '103', mpGuid: mpGuid } }">
            <span class="overview-button" :style="{ backgroundColor: '#337ab7', color: 'white' }">{{
              $t('GroundWaterDataTableOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'106'">
          <router-link :title="$t('GroundAirTableOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '106', mpGuid: mpGuid } }">
            <span class="overview-button" :style="{ backgroundColor: '#d25e88', color: 'white' }">{{
              $t('GroundAirTableOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'105'">
          <router-link :title="$t('SurfaceSamplesTableOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '105', mpGuid: mpGuid } }">
            <span class="overview-button" :style="{ backgroundColor: '#676e9b', color: 'white' }">{{
              $t('SurfaceSamplesTableOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'101'">
          <router-link :title="$t('LocationVisits.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '101' } }">
            <span class="overview-button" :style="{ backgroundColor: '#d25c24', color: 'white' }">{{
              $t('LocationVisits.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'107'">
          <router-link :title="$t('ProjectParticipantsOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '107' } }">
            <span class="overview-button" :style="{ backgroundColor: '#4a9faa', color: 'white' }">{{
              $t('ProjectParticipantsOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <!-- This is part of the refactor that was too ambitious for the amount of effort estimated.
          -- Check the refactorTableOverviews folder for information.
          -- I believe it is a proper way to reintroduce the tables but requires tremendous effort
          -- to implement and fix our standing bugs, which we don't want to spend time on. -Nick -->
        <!-- <li :key="'112'">
          <router-link
            :title="'Test'"
            class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '112' } }"
          >
            <span
              class="overview-button"
              :style="{ backgroundColor: '#FF55CC', color: '#993366' }"
              >TEST</span
            >
          </router-link>
        </li> -->
        <li v-for="overview in orderedOverviews" :key="overview.id">
          <router-link :title="overview.name" class="overview-link" :to="{
            name: 'overview',
            params: { overviewId: overview.id.toString() }
          }">
            <span class="overview-button" :style="{
              backgroundColor: overview.backgroundColor,
              color: overview.textColor
            }">{{ overview.name }}</span>
          </router-link>
        </li>
        <li :key="'100'">
          <router-link :title="$t('AnalysesResults.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '100' } }">
            <span class="overview-button" :style="{ backgroundColor: '#d2a723', color: 'white' }">{{
              $t('AnalysesResults.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'113'">
          <router-link title="Manual input" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '113' } }">
            <span class="overview-button" :style="{ backgroundColor: '#d2a723', color: 'white' }">{{
              $t('manualAnalysisResultInput.buttons.title') }}</span>
          </router-link>
        </li>
        <li :key="'110'">
          <router-link :title="$t('PictureGalleryOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '110' } }">
            <span class="overview-button" :style="{ backgroundColor: '#cc3333', color: 'white' }">{{
              $t('PictureGalleryOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <li :key="'111'">
          <router-link :title="$t('AttachmentGalleryOverview.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '111' } }">
            <span class="overview-button" :style="{ backgroundColor: '#36BFBF', color: 'white' }">{{
              $t('AttachmentGalleryOverview.PageTitle') }}</span>
          </router-link>
        </li>
        <hr style="margin: 0px" />
        <h4 style="color: white; padding-left: 0.5rem; font-size: 22px; font-weight: 400px">
          {{ $t('tableTitles.AllProjects') }}
        </h4>
        <li :key="'114'">
          <router-link :title="$t('AllLabassignments.PageTitle')" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '114' } }">
            <span class="overview-button" :style="{ backgroundColor: '#d25c24', color: 'white' }">{{
              $t('AllLabassignments.PageTitle') }}</span>
          </router-link>
        </li>
        <li v-if="allowOverviews" :key="'99'">
          <router-link :title="'Overzicht'" class="overview-link"
            :to="{ name: 'overview', params: { overviewId: '99' } }">
            <span class="overview-button" :style="{ backgroundColor: 'blue', color: 'white' }">Overzicht</span>
          </router-link>
        </li>
      </ul>
    </aside>

    <div v-if="!$route.params.overviewId" class="no-overview">
      <span>{{ $t('label.selectOverview') }}</span>
    </div>
    <div v-else-if="$route.params.overviewId === '99'" class="overview-tables">
      <sql-overview />
    </div>
    <div v-else-if="$route.params.overviewId === '100'" class="overview-tables">
      <TableAllSamplesWithResults :project-id="$route.params.projectId" />
    </div>
    <div v-else-if="$route.params.overviewId === '101'" class="overview-tables">
      <LocationVisitsTable :projectId="$route.params.projectId" />
    </div>
    <div v-else-if="$route.params.overviewId === '102'" class="overview-tables">
      <SoilDataTableOverview />
    </div>
    <div v-else-if="$route.params.overviewId === '103'" class="overview-tables">
      <GroundWaterDataTableOverview :projectId="$route.params.projectId" />
    </div>
    <div v-else-if="$route.params.overviewId === '104'" class="overview-tables">
      <MeasurementPointsOverview />
    </div>
    <div v-else-if="$route.params.overviewId === '105'" class="overview-tables">
      <SurfaceSamplesTableOverview />
    </div>
    <div v-else-if="$route.params.overviewId === '106'" class="overview-tables">
      <GroundAirTableOverview />
    </div>
    <div v-else-if="$route.params.overviewId === '107'" class="overview-tables">
      <ProjectParticipantsOverview />
    </div>
    <div v-else-if="$route.params.overviewId === '108'" class="overview-tables">
      <ProjectOverview />
    </div>
    <div v-else-if="$route.params.overviewId === '110'" class="overview-tables">
      <PictureGalleryOverview />
    </div>
    <div v-else-if="$route.params.overviewId === '111'" class="overview-tables">
      <AttachmentGalleryOverview />
    </div>
    <div v-else-if="$route.params.overviewId === '113'" class="overview-tables">
      <ManualAnalysesInput :projectId="$route.params.projectId" />
    </div>
    <div v-else-if="$route.params.overviewId === '114'" class="overview-tables">
      <AllLabAssignmentsOverview />
    </div>
    <!-- <div v-else-if="$route.params.overviewId === '112'" class="overview-tables">
      <TestOverview />
    </div> -->
    <div v-else-if="overview && !isExcludedOverview" class="overview-tables">
      <div v-for="(table, key) in overview.tables" :key="key" class="overview-table" :style="{ height: tableHeight }">
        <div :is="table.name" :ref="table.name" :childTables="table.childTables"
          :project-id="parseInt($route.params.projectId)" :useMasterDetail="table.useMasterDetail"></div>
      </div>
    </div>

    <div v-else class="no-overview">
      <span>{{ $t('label.noOverview') }}</span>
    </div>
    <app-footer />
    <confirm-modal :show="showLeaveWarningModal" :title-label="$t('label.AreYouSure')"
      :confirm-button-label="$t('button.confirm')" :cancel-button-label="$t('button.cancel')"
      @close-modal="showLeaveWarningModal = false" @confirm-button-pressed="navigateToNext">
      {{ $t('message.unsavedChanges') }}
    </confirm-modal>
    <nv-loading :show="loading"></nv-loading>

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup" />
  </div>
</template>

<script>
import authenticationProviders from '@/utils/authenticationProvider'
import config from '@/configurations/config-environment'

import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import TableCadastralOvam from '@/components/tables/tableCadastralOvam.vue'
import TableCadastralOvamVlaremAct from '@/components/tables/tableCadastralOvamVlaremAct'
import TableCadastralOvamCustomers from '@/components/tables/tableCadastralOvamCustomers'
import ConfirmModal from '@/components/modal/confirmModal'
import sqlOverview from '@/components/sqlOverview'
import TableAllSamplesWithResults from '@/components/tables/AnalysisResults/tableAllSamplesWithResults'
import nvLoading from '@/components/_shared/loading.vue'
import { bus } from '@/bus'
import _ from 'lodash'
import LocationVisitsTable from '@/components/dynamicTables/tables/locationVisitsTable'
import GroundWaterDataTableOverview from '@/components/table-overviews/groundWaterDataTableOverview'
import SoilDataTableOverview from '@/components/table-overviews/soilDataTableOverview'
import MeasurementPointsOverview from '@/components/table-overviews/measurementPointsOverview'
import SurfaceSamplesTableOverview from '@/components/table-overviews/surfaceSamplesTableOverview'
import GroundAirTableOverview from '@/components/table-overviews/groundAirTableOverview'
import ProjectParticipantsOverview from '@/components/table-overviews/projectParticipantsOverview'
import PictureGalleryOverview from '@/components/table-overviews/pictureGalleryOverview'
import AttachmentGalleryOverview from '../components/table-overviews/attachmentGalleryOverview'
import ManualAnalysesInput from '@/components/table-overviews/manualAnalysesInput.vue'
import ProjectOverview from '../components/table-overviews/projectOverview'
import AllLabAssignmentsOverview from '@/components/table-overviews/allLabAssignmentsOverview.vue'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

// import { TestOverview } from '../components/betterTableOverviews/overviews'

const authenticationProvider = authenticationProviders[config.platform].default

export default {
  name: 'Overview',
  components: {
    nvLoading,
    AppHeader,
    AppFooter,
    TableCadastralOvam,
    TableCadastralOvamVlaremAct,
    TableCadastralOvamCustomers,
    ConfirmModal,
    sqlOverview,
    TableAllSamplesWithResults,
    LocationVisitsTable,
    GroundWaterDataTableOverview,
    SoilDataTableOverview,
    MeasurementPointsOverview,
    SurfaceSamplesTableOverview,
    GroundAirTableOverview,
    ProjectParticipantsOverview,
    PictureGalleryOverview,
    AttachmentGalleryOverview,
    ProjectOverview,
    AllLabAssignmentsOverview,
    ManualAnalysesInput,
    ExportProjectPopup
    // TestOverview
  },
  data() {
    return {
      showLeaveWarningModal: false,
      next: null,
      menuExpanded: false,
      overviews: [
        // An array of all available overviews
        {
          order: 4, // The position the item will have in the left menu bar
          id: 1, // Overview ID, this is also the route where the overview will be available
          name: 'OVAM', // The full name of the overview, this will be displayed when the left menu is expanded
          key: 'OV', // The key of the overview, this is displayed in the icon in the left menu
          backgroundColor: '#942020', // The background colour of the icon in the left menu
          textColor: '#fff', // The text colour of the icon in the left menu
          tables: [
            {
              name: 'tableCadastralOvam',
              childTables: ['tableCadastralOvamVlaremAct', 'tableCadastralOvamCustomers']
            },
            {
              name: 'tableCadastralOvamVlaremAct'
            },
            {
              name: 'tableCadastralOvamCustomers'
            }
          ], // An array of all the tables displayed in the overview. The names must match the names of the table component /components/tables/
          tileViewRoute: {
            name: 'project',
            params: { projectId: this.$route.params.projectId.toString() }
          } // The location object where the tile button should return to.
        }
      ],
      excludedOverViewIDS: ['99', '100', '101'],
      exportPopupOpen: false
    }
  },
  computed: {
    loading: function () {
      return this.$store.getters.isLoading
    },
    /* Flag to access controll the overviews button */
    allowOverviews: function () {
      return authenticationProvider.getAuthenticatedUser().language === 'nl'
    },
    /* The currently selected overview */
    overview: function () {
      return this.overviews.find((overview) => overview.id.toString() === this.$route.params.overviewId.toString())
    },
    orderedOverviews: function () {
      return _.orderBy(this.overviews, 'order')
    },
    tableHeight: function () {
      return 100 / this.overview.tables.length + '%'
    },
    selected: {
      get() {
        return this.$store.state.selected
      },
      set(value) {
        this.$store.commit('setSelected', value)
      }
    },
    isExcludedOverview() {
      return this.excludedOverViewIDS.includes(this.$route.params.overviewId)
    },
    mpGuid() {
      return this.$route.params.mpGuid
    },
    projectId() {
      return this.$route.params.projectId
    }
  },
  methods: {
    /* Expands or collapses the left menu */
    expandMenu() {
      this.menuExpanded = !this.menuExpanded
    },

    async xyzImport() {
      this.$router.push({
        name: 'ExternalXYZ',
        params: {
          PrID: this.projectId
        }
      })
    },

    /* Navigates to the route stored in this.next */
    navigateToNext() {
      if (this.next) {
        this.$store.commit('setSelected', {})
        this.next()
      }
    },

    /* Loops trough all tables currently displayed and returns true if one more of the tables have unsaved changes */
    checkIfTablesHaveChanges() {
      let unsavedChanges = false
      if (!this.isExcludedOverview) {
        if (this.overview) {
          this.overview.tables.forEach((table) => {
            if (this.$refs[table.name] && this.$refs[table.name][0] && this.$refs[table.name][0].$refs.tableBase) {
              if (this.$refs[table.name][0].$refs.tableBase.hasChanges()) {
                unsavedChanges = true
              }
            }
          })
        }
      }
      return unsavedChanges
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  },

  created() {
    this.$store
      .dispatch('fetchProject', {
        PrID: this.$route.params.projectId
      })
      .then(() => {
        this.$store.state.activeTitle = this.$store.state.currentProject.PrCode
      })
    window.onbeforeunload = () => {
      if (this.checkIfTablesHaveChanges()) {
        return confirm(this.$t('message.confirm_exit'))
      }
    }
    bus.$on('updateSelected', (selected) => {
      this.$set(this.selected, selected.key, selected.data)
      // this.selected = { ...this.selected, [selected.key]: selected.data }
    })
    bus.$on('removeSelected', (key) => {
      this.$delete(this.selected, key)
    })
  },
  /* Make sure that user confirms navigating away when there are unsaved changes. Do this when the route is left (navigating away from /overviews) and when the route updates (changing the overview) */
  beforeRouteLeave(to, from, next) {
    if (this.isExcludedOverview) {
      this.navigateToNext()
    }
    let unsavedChanges = this.checkIfTablesHaveChanges()
    this.next = next
    if (unsavedChanges) {
      this.showLeaveWarningModal = true
      next(false) // This is done to resolve the hook
    } else {
      this.navigateToNext()
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isExcludedOverview) {
      next()
    }
    let unsavedChanges = this.checkIfTablesHaveChanges()
    if (unsavedChanges) {
      this.next = next
      this.showLeaveWarningModal = true
    } else {
      next()
    }
  }
}
</script>

<style lang="less" scoped>
#overview {
  position: relative;
  height: 100%;
  overflow: hidden;

  aside {
    height: 100%;
    overflow: hidden;

    .expand {
      position: absolute;
      right: -0.75rem;
      top: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      border-radius: 1.5rem;
      color: #ffffff;
      background-color: #67ac45;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      cursor: pointer;
      line-height: 1.5rem;
      transition: transform 0.2s ease-out;
      transform: rotate(0deg);
    }

    .rotated {
      transform: rotate(180deg) translateY(-1px);
    }
  }

  .asideExpanded {
    display: flex;
    flex-direction: column;
    width: 18rem;
    float: left;
    z-index: 10;
    background-color: #515151;
    overflow: hidden;
    padding: 5px 5px;
  }

  .overview-list {
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0.5rem;
    overflow: auto;

    .router-link-exact-active {
      .overview-button {
        border: solid 1px #fff;
      }
    }

    li {
      margin-bottom: 0.5rem;
      overflow: hidden;

      .overview-link {
        color: #ffffff;
        display: block;
        height: 2.5rem;
        overflow: hidden;

        &:hover {
          text-decoration: none;

          .overview-button {
            transform: scale(0.9);
          }
        }

        &:active {
          text-decoration: none;
        }

        &:visited {
          text-decoration: none;
        }

        .overview-button {
          display: block;
          min-width: 100%;
          height: 2.5rem;
          text-align: left;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 2.5rem;
          border-radius: 4px;
          text-decoration: none;
          transition-duration: 0.2s;
          transition-property: transform;
          font-size: 1.2rem;
          float: left;

          &:hover {
            text-decoration: none;
            transform: scale(0.95);
          }
        }
      }
    }
  }

  .no-overview {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    padding-top: 50px;
    text-align: center;

    span {
      font-size: 1.5em;
      color: #717171;
    }
  }

  .overview-tables {
    padding-top: 0.7rem;
    padding-right: 0.7rem;
    padding-bottom: 0.7rem;
    padding-left: 4.7rem;
    height: 100%;

    .overview-table {
      overflow: hidden;

      >div {
        height: 100%;
      }
    }
  }
}

.dx-datagrid .dx-row>td {
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 2px !important;
}
</style>
