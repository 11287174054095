<template>
  <div class="pre-export-footer-container">
    <div class="pre-export-footer">
      <button class="pre-export-footer-button secondary-button" @click="handlePageChangeClick('SelectExportType')">
        <i class="fa-regular fa-chevron-left"></i>
        {{ $t('export.label.back') }}
      </button>
      <button class="pre-export-footer-button primary-button" :disabled="!exportButtonsAreEnabled" @click="handlePageChangeClick('CreateExport')">
        {{ $t('label.export') }}
        <i class="fa-regular fa-cloud-download"></i>
      </button>
    </div>
  </div>
</template>

<script>
import '@/assets/less/colors.less'

export default {
  name: 'PreExportCheckFooter',
  props: {
    exportButtonsAreEnabled: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handlePageChangeClick(page) {
      const nextFooter = page === 'CreateExport' ? 'CreateExportFooter' : 'SelectExportTypeFooter'
      this.$emit('setNextPage', {
        nextComponent: page,
        nextFooter: nextFooter
      })
      this.$emit('goNextPage')
    }
  }
}
</script>

<style lang="less" scoped>
  .pre-export-footer-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;
    .pre-export-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 15px 32px;
      gap: 16px;
      .pre-export-footer-button {
        display: flex;
        padding: 6px 18px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
      }
      .primary-button {
        border: none;
        background-color: @greenTerra600;
        color: white;
        &:hover {
          background-color: @greenTerra700;
        }
        &:disabled {
          background-color: @gray200;
          cursor: not-allowed;
        }
      }
      .secondary-button {
        border: 1px solid @gray400;
        background-color: white;
        color: @gray700;
        &:hover {
          background-color: @gray100;
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
</style>
