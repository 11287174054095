<template>
  <div class="bro-check-body">
    <p class="bro-check-header">{{ $t('preExport.label.broData') }}</p>
    <p class="bro-check-explanation">{{ $t('preExport.label.broExplanation') }}</p>
    <div class="bro-check-content">
      <div class="terraindex-bro-id">
        <label>{{ $t('preExport.label.broIdTerraIndex') }}</label>
        <div class="terraindex-bro-id-input">
          <i v-if="validatedBROID !== projectBROID" class="fa-regular fa-triangle-exclamation warning" />
          <i v-if="validatedBROID === projectBROID && projectBROID" class="fa-regular fa-circle-check success" />
          <input type="text" :value="projectBROID" disabled />
        </div>
      </div>

      <button type="button" class="sync-button primary-button" :disabled="validatedBROID === projectBROID" @click="storeBROID()">
        <i class="fa-regular fa-left-to-bracket"></i>
        {{ $t('preExport.label.takeOver') }}
      </button>

      <div class="broservice-bro-id">
        <label>{{ $t('preExport.label.broIdPortal') }}</label>
        <input type="text" :value="validatedBROID" disabled />
      </div>
    </div>

    <p v-if="!projectBROID && !validatedBROID" class="bro-check-action-message">{{ actionMessage }}</p>
    <p v-else-if="validatedBROID === projectBROID && projectBROID" class="bro-check-action-message success">{{ actionMessage }}</p>
    <p v-else class="bro-check-action-message warning">{{ actionMessage }}</p>
  </div>
</template>

<script>
import '@/assets/less/colors.less'
import { BROService } from '@/services/BROService/BROService'
import stringUtils from '@/utils/stringUtils'

export default {
  name: 'BROSADIdCheck',
  components: {
  },
  data() {
    return {
      BROService: new BROService(),
      actionMessage: '',
      projectBROID: null,
      validatedBROID: null
    }
  },
  mounted() {
    this.projectBROID = this.$store?.state?.currentProject?.BroId
    this.BROService.getBROID(this.$store?.state?.currentProject?.PrGuid).then(response => {
      this.validatedBROID = response[0]?.broId

      this.updateValidationMessage()
    })
  },
  methods: {
    async storeBROID() {
      this.$store.state.currentProject.BroId = this.validatedBROID || ''

      await this.$store.dispatch('setProject', { projectData: this.$store.state.currentProject }).then(result => {
        this.projectBROID = result.BroId
        this.updateValidationMessage()
      })

      await this.$store.dispatch('setCurrentProject', this.$store.state.currentProject)
    },
    updateValidationMessage() {
      const projectBROIDSpecified = stringUtils.isNullOrEmptyOrWhitespace(this.projectBROID) === false
      const validatedBROIDSpecified = stringUtils.isNullOrEmptyOrWhitespace(this.validatedBROID) === false

      if (!projectBROIDSpecified && !validatedBROIDSpecified) {
        this.actionMessage = this.$t('preExport.messages.broIdNotFound')
      } else if (projectBROIDSpecified && !validatedBROIDSpecified) {
        this.actionMessage = this.$t('preExport.messages.broIdMismatch')
      } else if (!projectBROIDSpecified && validatedBROIDSpecified) {
        this.actionMessage = this.$t('preExport.messages.broIdMismatch')
      } else if (this.projectBROID !== this.validatedBROID) {
        this.actionMessage = this.$t('preExport.messages.broIdMismatch')
      } else if (this.projectBROID === this.validatedBROID) {
        this.actionMessage = this.$t('preExport.messages.broIdMatch')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.bro-check-body {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
  }

  .bro-check-header {
    color: @gray900;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .bro-check-explanation {
    max-width: 650px;
    color: @gray900;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .bro-check-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    font-size: 14px;
    line-height: 20px;

    input {
        display: flex;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid @gray400;
        background: @gray50;
      }

    .terraindex-bro-id {
      display: flex;
      flex-direction: column;

      .terraindex-bro-id-input {
        position: relative;

        i {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      input {
        padding-left: 28px;
      }
    }

    .sync-button {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 24px;
      padding: 12px 20px;
      border-radius: 4px;
      border: 1px solid #9DA3AD;
      background: #FFF;
    }
    .sync-button:disabled {
      border: 1px solid @gray200;
      color: @gray300;
    }

    .broservice-bro-id {
      display: flex;
      flex-direction: column;

      input {
        border: 1px dashed @gray300;
        background: @gray50;
      }
    }
  }

  .bro-check-action-message {
    margin-top: 6px;
    font-size: 14px;
    line-height: 20px;
    color: @gray500;
  }

  .success {
    color: @greenTerra600;
  }
  .warning {
    color: @warning600;
  }
}
</style>
