import Vue from 'vue'
import $store from '@/vuex/store'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'
import ExportProjectFieldUpdate from '@/components/export/class/ExportProjectFieldUpdate'

const dataws = config.apis.dataws
export class ExportService extends BaseService {
  async setExportProjects(exportProjects) {
    for (const project of exportProjects) {
      await this.setExportFiles(project.PrID, project.selectedExportConfig)
    }
  }

  async setExportFiles(PrID, selectedExportConfig) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    selectedExportConfig = selectedExportConfig || {}
    const useCompression = selectedExportConfig.useCompression || false
    const data = {
      Authorisation: authorisation,
      WebserviceVersion: '1.0',
      LanguageCode: languageCode,
      UseCompression: useCompression,
      TypeOfExport: selectedExportConfig.exportServerIdentifier,
      Parameters: [
        {
          ParameterType: 'ProjectID',
          FloatValue: PrID,
          StringValue: PrID
        }
      ],
      WithFilteringOnFieldExport: true,
      LogFormat: 'JSON',
      UseZipStream: false
    }
    return Vue.http
      .post(`${dataws}ITWImportExportRestService_V1_0/export`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (!response) return
        return this.saveExportProject(response.data, PrID)
      })
      .catch((error) => {
        return console.error(error)
      })
  }

  saveExportProject(response, PrID) {
    const ExportResponseFieldValue = new ExportProjectFieldUpdate('exportResponse', response, PrID)
    $store.commit('updateExportProjectBindingKey', ExportResponseFieldValue)
  }

  downloadExportFiles(response) {
    response.forEach(project => {
      if (project.exportResponse.Files.length === 0) return
      this.downloadFile(project.exportResponse.Files[0], project.selectedExportConfig, project.PrCode)
    })
  }

  downloadFile(file, selectedExportConfig, PrCode) {
    const fileExtension = selectedExportConfig.extension
    const { content, type } = this.decodeFileContent(file.FileContent, fileExtension)

    const blob = new Blob([content], { type })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)

    const fileName = this.changeFileName([{ selectedExportConfig, PrCode }])
    link.download = fileName

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(link.href)
  }

  decodeFileContent(fileContent, fileExtension) {
    switch (fileExtension) {
      case 'zip':
      case 'ti':
        const decodedContent = atob(fileContent)
        const blobContent = new Uint8Array([...decodedContent].map(char => char.charCodeAt(0)))
        return { content: blobContent, type: 'application/zip' }
      default:
        return { content: fileContent, type: 'text/xml' }
    }
  }

  changeFileName(project) {
    return project[0].selectedExportConfig.extension
      ? `${project[0].PrCode.replace(/[-.,_{}()\\/:*?"<> ]/g, '')}.${project[0].selectedExportConfig.extension}`
      : `${project[0].PrCode.replace(/[-.,_{}()\\/:*?"<> ]/g, '')}`
  }
}
