<template>
  <div class="exportlog-json-content-body">

    <p>{{ $t('export.label.alerts') }}</p>
    <div class="toggle-bar">
      <button class="toggle-button success" :class="{'active': showSuccess}" :title="$t('export.label.alertSuccess')" @click="toggleSuccess">
        <i class="fa-solid fa-circle-check"></i>
        <p>{{ amountSuccess }}</p>
      </button>
      <button class="toggle-button warning" :class="{'active': showAttention}" :title="$t('export.label.alertWarning')" @click="toggleAttention">
        <i class="fa-solid fa-triangle-exclamation"></i>
        <p>{{ amountAttention }}</p>
      </button>
      <button class="toggle-button error" :class="{'active': showErrors}" :title="$t('export.label.alertError')" @click="toggleErrors">
        <i class="fa-solid fa-circle-exclamation"></i>
        <p>{{ amountError }}</p>
      </button>

      <div class="export-log-button-container">
        <button class="export-log-button-container-button" @click="downloadExportLog">
          <p class="export-log-button-text">{{ $t('export.label.exportLog') }}</p>
          <i class="fa-regular fa-arrow-up-right-from-square"></i>
        </button>
      </div>
    </div>

    <div ref="htmlContent" class="export-log-content">
      <div v-for="alertsPerEntity in exportLog" :key="alertsPerEntity[0].Title" class="export-log-entity">
        <p>{{ alertsPerEntity[0].Title }}</p>
        <div v-for="alert in alertsPerEntity" :key="alert.Message" class="export-log-alert" :class="getAlertClass(alert)">
          <i v-if="alert.Type === 'SUCCES'" class="fa-solid fa-circle-check"></i>
          <i v-if="alert.Type === 'ATTENTION'" class="fa-solid fa-triangle-exclamation"></i>
          <i v-if="alert.Type === 'ERROR'" class="fa-solid fa-circle-exclamation"></i>
          {{ alert.Message }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import '@/assets/less/colors.less'

import dateFormatter from '@/utils/dateFormatter'
import arrayUtils from '@/utils/arrayUtils'

export default {
  name: 'ExportLogFromJson',
  props: {
    projectName: {
      type: String,
      required: true
    },
    jsonExportLog: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      parsedExportLog: null,
      showSuccess: false,
      showAttention: true,
      showErrors: true
    }
  },
  created: function () {
    this.parsedExportLog = JSON.parse(this.jsonExportLog).ArrayOfExportLogRecord.ExportLogRecord
  },
  methods: {
    shouldIncludeInGroup(item) {
      return (item.Type !== 'SUCCES' || this.showSuccess) &&
      (item.Type !== 'ATTENTION' || this.showAttention) &&
      (item.Type !== 'ERROR' || this.showErrors)
    },
    toggleSuccess() {
      this.showSuccess = !this.showSuccess
    },
    toggleAttention() {
      this.showAttention = !this.showAttention
    },
    toggleErrors() {
      this.showErrors = !this.showErrors
    },
    getAlertClass(alert) {
      return alert.Type === 'ERROR' ? 'error-log' : alert.Type === 'ATTENTION' ? 'warning-log' : 'success-log'
    },
    downloadExportLog() {
      const styles = [...document.styleSheets]
        .map(styleSheet => {
          try {
            return [...styleSheet.cssRules]
              .map(rule => rule.cssText)
              .join('\n')
          } catch (error) {
            // Some external stylesheets, like DevExtreme, cannot be downloaded, ignore them
            return ''
          }
        })
        .join('\n')

      const completeHtml = `
          <html>
            <head>
              <style>${styles}</style>
            </head>
            <body class="exportlog-json-content-body" style="overflow-y: scroll; height: 100%">
              <p style="font-size: 24px;margin: 16px;">${this.projectName}</p>
              <p style="font-size: 16px;margin: 16px;">${this.$t('export.label.createdAt')} ${dateFormatter.formatDate(this.parsedExportLog[0].Timestamp)}</p>
              ${this.$refs.htmlContent.outerHTML}
            </body>
          </html>
        `

      const blob = new Blob([completeHtml], { type: 'text/html' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = this.fileName
      link.click()

      URL.revokeObjectURL(link.href)
    }
  },
  computed: {
    exportLog() {
      return arrayUtils.groupBy(this.parsedExportLog, 'Title', this.shouldIncludeInGroup)
    },
    amountSuccess() {
      return this.parsedExportLog.filter(item => item.Type === 'SUCCES').length
    },
    amountAttention() {
      return this.parsedExportLog.filter(item => item.Type === 'ATTENTION').length
    },
    amountError() {
      return this.parsedExportLog.filter(item => item.Type === 'ERROR').length
    }
  }
}
</script>

<style lang="less" scoped>
.exportlog-json-content-body {
  display: flex;
  flex-direction: column;

  .toggle-bar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-bottom: 8px;
    position: sticky;
    top: 0;
    background-color: white;

    .toggle-button {
      border: none;
      border-radius: 6px;
      padding: 9px 16px;

      p {
        display: inline-block;
        margin-left: 11px;
        height: 8px;
      }
    }
    .toggle-button {
      background: @gray200;

      &:hover {
        background: @gray200;
      }
    }
    .toggle-button.success.active {
      color: @greenTerra800;
      background: @greenTerra200;
    }
    .toggle-button.success > i {
      color: @greenTerra800;
    }
    .toggle-button.warning.active {
      color: @warning800;
      background: @warning100;
    }
    .toggle-button.warning > i {
      color: @warning800;
    }
    .toggle-button.error.active {
      color: @error800;
      background: @error200;
    }
    .toggle-button.error > i {
      color: @error800;
    }
  }

  .success-log {
    background: @greenTerra200;

    i {
      color: @greenTerra800;
    }
  }
  .warning-log {
    background: @warning100;

    i {
      color: @warning800;
    }
  }
  .error-log {
    background: @error200;

    i {
      color: @error800;
    }
  }
  .export-log-button-container {
    margin-left: auto;

    .export-log-button-container-button {
      display: flex;
      align-items: center;
      padding: 6px 18px;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid @gray400;
      background: white;
      .export-log-button-text {
        margin-bottom: 0px;
        color: @gray700;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        white-space: nowrap;
      }
      &:hover {
        background: @gray50;
      }
    }
  }
  .export-log-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 3;

    .export-log-entity {
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-radius: 6px;
      padding: 16px;
      margin-top: 4px;
      background: @gray50;

      p {
        color: @gray700;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }
      .export-log-alert {
        padding: 4px 8px;
        border-radius: 4px;
      }
    }
  }
}

</style>
